<template>
  <div>
    <v-btn
      :icon="iconOnly"
      text
      @click="handlePdf"
      :class="`textColor--${textColor}`"
    >
      <v-icon
        v-if="iconOnly || prependIcon"
        :color="textColor"
        :size="iconSize"
        :title="pdfType"
        :class="iconOnly ? 'mr-0' : 'mr-2'"
        >{{ prependIcon }}</v-icon
      >
      {{ iconOnly ? "" : buttonText }}
      <v-icon
        v-if="!iconOnly && appendIcon"
        color="white"
        :size="iconSize"
        :title="pdfType"
        class="ml-2"
        >{{ appendIcon }}</v-icon
      >
    </v-btn>

    <v-dialog v-model="dialogPdf" persistent width="600px">
      <v-card>
        <v-card-text>
          <v-container>
            <div class="d-flex flex-column align-center justify-center my-12">
              <h3 v-if="dialogPdfErrorText">{{ dialogPdfErrorText }}</h3>
              <h3 v-else class="mb-8">
                Please wait while your {{ dialogPdfTypeText }} PDF is
                generated...
              </h3>
              <v-btn
                v-if="dialogPdfErrorText"
                class="mt-8"
                color="error"
                @click="dialogPdf = false"
                >Close</v-btn
              >
              <v-progress-circular
                v-else
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    pdfType: {
      type: String,
      required: true,
    },
    prospectId: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
    },
    iconOnly: {
      // requires prependIcon
      type: Boolean,
      default: true,
    },
    appendIcon: {
      type: String,
    },
    prependIcon: {
      type: String,
    },
    textColor: {
      type: String,
      default: "white",
    },
    iconSize: {
      type: String,
    },
  },
  data() {
    return {
      dialogPdf: false,
      dialogPdfTypeText: "",
      dialogPdfErrorText: "",
    };
  },

  methods: {
    async handlePdf() {
      this.dialogPdfErrorText = "";
      this.dialogPdfTypeText =
        this.pdfType === "print" ? "print-ready" : "downloadable";
      this.dialogPdf = true;

      try {
        const response = await fetch(
          `${process.env.VUE_APP_PDF_API}?report_id=${this.prospectId}&pdfType=${this.pdfType}`
        );

        const data = await response.json();

        if (!data.pdfUrl) {
          throw Error(
            "Sorry, your PDF could not be generated. Please try again."
          );
        }
        this.dialogPdfTypeText = "";
        this.dialogPdf = false;

        window.open(data.pdfUrl, "_blank");
      } catch (error) {
        console.log(error);
        this.dialogPdfErrorText = error;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
